<template>
  <div>
    <img
      style="cursor: pointer;"
      src="@/assets/edit_pencil.svg"
      @click="handleClick"
    />
  </div>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.params.editValue(this.params.value);
    },
  },
};
</script>
