<template>
  <div class="responsive-bar">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <BaseDialog
      :show-modal="addRoomModal"
      :confirm-button-text="$t('btn.save')"
      @cancel-action="addRoomModal = false"
      @submit-form="createRoom"
      is-form
    >
      <h3 class="text-center">{{ $t("btn.add_room").replace(" ", "") }}</h3>
      <b-form-input
        v-model="roomName"
        :placeholder="$t('asset.room')"
        required
      ></b-form-input>
    </BaseDialog>
    <BaseDialog
      :show-modal="editRoomModal"
      :confirm-button-text="$t('btn.save')"
      @cancel-action="cancelEditRoom"
      @submit-form="updateRoom"
      is-form
    >
      <h3 class="text-center">{{ $t("btn.add_room").replace(" ", "") }}</h3>
      <b-form-input
        v-model="roomName"
        :placeholder="$t('asset.room')"
        required
      ></b-form-input>
    </BaseDialog>
    <BaseDialog
      :show-modal="deleteRoomModal"
      :confirm-button-text="$t('btn.save')"
      confirm-button-color="#ff0000"
      @cancel-action="cancelDeleteRoom"
      @confirm-action="deleteRoom"
    >
      <div class="row">
        <img class="del-icon mt-2" src="../../../assets/delete_bin.svg" />
        <p class="text-center">
          {{ $t("dialog.confirm_delete_room") }}
        </p>
      </div>
    </BaseDialog>
    <SuccessDialog :msg="successMessage" v-show="showSuccess"></SuccessDialog>
    <AdminMenuBar />
    <div class="admin">
      <AdminSlideMenu />
      <div class="backHome-icon">
        <img
          src="../../../assets/previous.svg"
          width="30"
          @click="$router.back()"
        />
      </div>
      <h1 class="header-text">
        <div class="topic">{{ $t("home.dt_manage") }}</div>
      </h1>
      <div style="padding: 0.4rem">
        <div
          class="text-start d-flex flex-wrap"
          v-if="buildingName && floorName"
        >
          <p>{{ buildingName }}</p>
          <p class="mx-2">></p>
          <p>{{ floorName }}</p>
        </div>
        <div class="container">
          <div class="row my-2">
            <div class="col-2">
              <button
                class="add-button text-light p-2"
                style="border: none"
                type="button"
                @click="addRoomModal = true"
              >
                + {{ $t("btn.add_room") }}
              </button>
            </div>
            <div class="col-10 col-md-9">
              <div class="d-flex">
                <span
                  class="filter-box p-2 d-flex mr-2"
                  id="basic-addon1"
                  style="border-radius: 10px 0 0 10px;"
                >
                  <img src="../../../assets/search-white.svg" width="20px" />
                </span>
                <input
                  type="text"
                  class="form-control filter-box"
                  style="
                    border-right-style: none;
                    border-radius: 0 10px 10px 0 !important;
                  "
                  :placeholder="$t('home.search')"
                  v-debounce:700ms="getRoomList"
                  v-model="searchText"
                />
              </div>
            </div>
          </div>
        </div>

        <RoomTable
          :roomData="roomList"
          :page="page"
          :per-page="perPage"
          @edit-value="showEditRoomModal"
          @delete-value="showDeleteRoomModal"
        ></RoomTable>
        <div class="d-flex justify-content-center mt-3">
          <b-pagination
            v-model="page"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";
import axios from "axios";
import { baseUrl } from "../../../util/backend";
import { authHeader } from "../../../store/actions";
import { mapGetters } from "vuex";
import AdminSlideMenu from "@/components/AdminSlideMenu.vue";
import AdminMenuBar from "@/components/AdminMenuBar.vue";
import BaseDialog from "../../../components/BaseDialog.vue";
import SuccessDialog from "@/components/SuccessDialog.vue";
import RoomTable from "../../../components/CustomTable/Hoshi/ManageRoomTable.vue";

export default {
  data() {
    return {
      successMessage: "",
      showSuccess: false,
      buildingName: "",
      floorName: "",
      addRoomModal: false,
      editRoomModal: false,
      editRoomId: null,
      deleteRoomModal: false,
      deleteRoomId: null,
      isLoading: false,
      page: 1,
      totalRows: 200,
      perPage: 40,
      id_company: null,
      roomList: [],
      roomName: "",
      searchText: "",
    };
  },
  components: {
    Loading,
    AdminSlideMenu,
    AdminMenuBar,
    RoomTable,
    BaseDialog,
    SuccessDialog,
  },

  computed: {
    ...mapGetters({}),
  },
  methods: {
    async createRoom() {
      this.isLoading = true;
      try {
        const res = await axios.post(
          `${baseUrl()}location/createRoom`,
          {
            id_company: this.id_company,
            id_location_floor: +this.$route.params.floorId,
            name: this.roomName,
          },
          authHeader()
        );
        this.successMessage = this.$t("dialog.add_success");
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 2000);
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
      this.isLoading = false;
      this.roomName = "";
      this.addRoomModal = false;
      this.getRoomList();
    },
    showEditRoomModal(room) {
      this.roomName = room.name;
      this.editRoomId = room.id_location_room;
      this.editRoomModal = true;
    },
    cancelEditRoom() {
      this.roomName = "";
      this.editRoomModal = false;
    },
    async updateRoom() {
      this.isLoading = true;
      try {
        const res = await axios.put(
          `${baseUrl()}location/updateRoom`,
          {
            id_company: this.id_company,
            id_location_floor: +this.$route.params.floorId,
            id_location_room: +this.editRoomId,
            name: this.roomName,
          },
          authHeader()
        );
        this.editRoomId = null;
        this.successMessage = this.$t("dialog.save_edit");
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 2000);
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
      this.isLoading = false;
      this.roomName = "";
      this.editRoomModal = false;
      this.getRoomList();
    },
    showDeleteRoomModal(roomId) {
      this.deleteRoomId = roomId;
      this.deleteRoomModal = true;
    },
    cancelDeleteRoom() {
      this.deleteRoomId = null;
      this.deleteRoomModal = false;
    },
    async deleteRoom() {
      this.isLoading = true;
      try {
        const res = await axios.delete(`${baseUrl()}location/deleteRoom`, {
          params: {
            id_company: this.id_company,
            id_location_floor: +this.$route.params.floorId,
            id_location_room: this.deleteRoomId,
          },
          ...authHeader(),
        });

        this.successMessage = this.$t("dialog.delete_success");
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 2000);
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
      this.isLoading = false;
      this.deleteRoomId = null;
      this.deleteRoomModal = false;
      this.getRoomList();
    },
    searchByText() {},
    async getRoomList() {
      this.isLoading = true;
      try {
        const res = await axios.get(`${baseUrl()}location/getRoom`, {
          params: {
            text: this.searchText,
            page: this.page,
            limit: this.perPage,
            id_company: this.id_company,
            id_location_floor: +this.$route.params.floorId,
          },
          ...authHeader(),
        });
        const floorData = res.data.data;
        this.buildingName = floorData.location_building;
        this.floorName = floorData.location_floor;
        this.roomList = floorData.rows;
        console.log();
        this.totalRows = floorData.count;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err);
        }
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.getRoomList();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .bm-menu {
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  box-shadow: 2px 0px 20px rgba(0, 0, 0, 0.2) !important;
}

.backHome-icon {
  position: absolute;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (max-width: 1025px) {
  .backHome-icon {
    display: none;
  }
}

.add-button {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  margin: 0 !important;
}

.del-icon {
  height: 50px;
  margin-bottom: 15px;
}
</style>
