<template>
  <ag-grid-vue
    style="width: 100%; height: 450px"
    class="ag-theme-alpine"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    :gridOptions="gridOptions"
    @grid-ready="onGridReady"
    suppressDragLeaveHidesColumns
    suppressMovableColumns
    checkboxSelection="true"
  >
  </ag-grid-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import EditPencil from "../../Table/components/editPencil.vue";
import DeleteBin from "../../Table/components/deleteBin.vue";

export default {
  components: {
    AgGridVue,
    EditPencil,
    DeleteBin,
  },
  props: ["page", "perPage", "roomData"],
  emits: ["edit-value", "delete-value"],
  data() {
    return {
      test: [{}, {}, {}],
      columnDefs: null,
      rowSelection: "multiple",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
      gridApi: "sizeColumnsToFit",
      id_user: null,
    };
  },

  beforeMount() {
    this.columnDefs = [
      {
        headerName: this.$t("count.order"),
        field: "index",
        // headerCheckboxSelection: true,
        // headerCheckboxSelectionFilteredOnly: true,
        // checkboxSelection: true,
        maxWidth: 110,
      },
      {
        headerName: this.$t("asset.room"),
        field: "name",
        sortable: true,
        minWidth: 200,
        flex: 1,
        suppressSizeToFit: true,
      },
      {
        headerName: this.$t("btn.edit"),
        field: "edit",
        cellRendererFramework: "EditPencil",
        suppressSizeToFit: true,
        cellRendererParams: {
          editValue: this.editValue.bind(this),
        },
      },
      {
        headerName: this.$t("btn.del_all"),
        field: "delete",
        cellRendererFramework: "DeleteBin",
        maxWidth: 130,
        suppressSizeToFit: true,
        cellRendererParams: {
          deleteValue: this.deleteValue.bind(this),
        },
      },
    ];
  },
  computed: {
    rowData() {
      // console.log(this.dataTable, "dataTable");
      return this.roomData.map((room, index) => {
        return {
          index: (this.page - 1) * this.perPage + index + 1,
          name: room.name,
          edit: { id_location_room: room.id_location_room, name: room.name },
          delete: {
            id_location_room: room.id_location_room,
            can_delete: room.can_delete,
          },
        };
      });
    },
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
  methods: {
    onGridReady() {
      // this.gridApi.sizeColumnsToFit();
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) => node.data);
      return selectedData;
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
    editValue(value) {
      this.$emit("edit-value", value);
    },
    deleteValue(value) {
      this.$emit("delete-value", value);
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
