<template>
  <div>
    <img
      style="cursor: pointer"
      src="@/assets/delete_bin.svg"
      v-if="params.value.can_delete"
      @click="handleClick"
    />
    <img v-else src="@/assets/delete_bin_disabled.svg" />
  </div>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.params.deleteValue(this.params.value.id_location_room);
    },
  },
};
</script>
